@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#zmmtg-root {
  display: none;
}
/* hide zoom meeting info */
.meeting-info-container.meeting-info-container--left-side {
  display: none;
}
/* zoom loading */
.loading-main {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}
.ax-outline-blue-important.btn.btn-sm.btn-default {
  display: none !important;
}
/* chat padding */
.chat-container__chat-control {
  padding: 20px 10px;
}
